import React, { useState, useEffect } from "react"
import ReactMapGL, { Marker, NavigationControl } from "react-map-gl"
import { Link } from "gatsby"

import Markersvg from "../images/marker.svg"
import OpenTime from "./opentime"

import locationIcon from "../images/location.svg"
import GPS from "../images/gps.svg"

const Map = props => {
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState(false)
  const [ownLoaction, setOwnLocation] = useState(null)
  const [viewport, setViewport] = useState(null)

  const [selectedStore, setSelectedStore] = useState(null)

  const [activestore, setActivestore] = useState(-1)

  const changeFilter = category => {
    setSearch(category)
  }
  let filtered = props.mapdata.edges.filter(edge => edge.node.data.store_categorie === search)

  useEffect(() => {
    setViewport({
      width: window.innerWidth,
      height: window.innerHeight,
      latitude: 48.6941789,
      longitude: 9.0044827,
      zoom: 12.5,
    })

    let lslat = localStorage.getItem("latitude")
    let lslong = localStorage.getItem("longitude")

    if (lslat && lslong) {
      let lslattonr = parseFloat(lslat)
      let lslongtonr = parseFloat(lslong)

      setOwnLocation({
        latitude: lslattonr,
        longitude: lslongtonr,
      })
    }
  }, [])

  const activeLocation = e => {
    e.preventDefault()

    setViewport({
      width: window.innerWidth,
      height: window.innerHeight,
      latitude: ownLoaction.latitude,
      longitude: ownLoaction.longitude,
      zoom: 12.5,
      transitionDuration: 1000,
    })
  }

  const toggleFilter = e => {
    e.preventDefault()
    setFilter(!filter)
  }

  return (
    <div className="map">
      <Link className="map__back-btn" to="/">
        Zurück zur Übersicht
      </Link>
      <button className="map-filter-btn" onClick={e => toggleFilter(e)}>
        Filter
      </button>
      {ownLoaction !== null ? (
        <button className="own-location" onClick={e => activeLocation(e)}>
          <img src={GPS}></img>
        </button>
      ) : null}

      {filter && (
        <div className="map-category">
          <ul>
            <li>
              {" "}
              <button className={search === "" && "active-category-map"} onClick={e => changeFilter("")}>
                Alle
              </button>
            </li>
            <li>
              <button
                className={search === "Restaurant" && "active-category-map"}
                onClick={e => changeFilter("Restaurant")}
              >
                Restaurant
              </button>
            </li>
            <li>
              <button className={search === "Cafe" && "active-category-map"} onClick={e => changeFilter("Cafe")}>
                Cafe
              </button>
            </li>
            <li>
              <button
                className={search === "Bäckerei" && "active-category-map"}
                onClick={e => changeFilter("Bäckerei")}
              >
                Bäckerei
              </button>
            </li>

            <li>
              <button
                className={search === "Einzelhandel" && "active-category-map"}
                onClick={e => changeFilter("Einzelhandel")}
              >
                Einzelhandel
              </button>
            </li>
          </ul>
        </div>
      )}

      {selectedStore ? (
        <Link to={selectedStore.uid} className="marker-overlay">
          <div className="marker-overlay__holder">
            <img className="marker-overlay__logo" src={selectedStore.data.store_logo.url}></img>
            <div className="marker-overlay__content">
              <h4>{selectedStore.data.store_title.text}</h4>
              <p>
                {" "}
                <i>
                  {selectedStore.data.store_categorie}, {selectedStore.data.store_service}
                </i>{" "}
              </p>
              <p>
                {selectedStore.data.store_desicription.text.substring(0, 60)}
                ...
              </p>
              <OpenTime times={selectedStore.data.store_openingtime} show="opening-short" />
              <p
                className="marker-overlay__close"
                onClick={e => {
                  e.preventDefault()
                  setSelectedStore(null)
                  setActivestore(-1)
                }}
              >
                x
              </p>
            </div>
          </div>
        </Link>
      ) : null}
      <ReactMapGL
        {...viewport}
        onViewportChange={setViewport}
        mapboxApiAccessToken="pk.eyJ1IjoibWptZWRpYSIsImEiOiJjanIwaGMwZncwMHhpNDJxbWVwM3ljZzRiIn0.K_lsh5IfRTaI-NqysZ4pwQ"
        mapStyle="mapbox://styles/mjmedia/ck8dfm7571des1jmv2dirihd4"
        className="store-map"
      >
        <div style={{ position: "absolute", right: 0 }}>
          <NavigationControl />
        </div>

        {ownLoaction !== null ? (
          <div>
            <Marker latitude={ownLoaction.latitude} longitude={ownLoaction.longitude} offsetTop={-35}>
              <img src={locationIcon}></img>
            </Marker>
          </div>
        ) : null}

        {search !== "" ? (
          <div>
            {filtered.map((store, index) => {
              return (
                <Marker
                  key={store.node.uid}
                  latitude={store.node.data.store_coordinates.latitude}
                  longitude={store.node.data.store_coordinates.longitude}
                  offsetTop={-35}
                >
                  <button
                    className="marker-btn"
                    onClick={e => {
                      e.preventDefault()
                      setSelectedStore(store.node)
                      setActivestore(index)
                      setViewport({
                        width: viewport.width,
                        height: viewport.height,
                        latitude: store.node.data.store_coordinates.latitude,
                        longitude: store.node.data.store_coordinates.longitude,
                        zoom: viewport.zoom,
                        transitionDuration: 1000,
                      })
                    }}
                  >
                    <img className={activestore === index ? "image-active" : "image"} src={Markersvg}></img>
                  </button>
                </Marker>
              )
            })}
          </div>
        ) : (
          <div>
            {props.mapdata.edges.map((store, index) => {
              return (
                <Marker
                  key={store.node.uid}
                  latitude={store.node.data.store_coordinates.latitude}
                  longitude={store.node.data.store_coordinates.longitude}
                  offsetTop={-35}
                >
                  <button
                    className="marker-btn"
                    onClick={e => {
                      e.preventDefault()
                      setSelectedStore(store.node)
                      setActivestore(index)
                      setViewport({
                        width: viewport.width,
                        height: viewport.height,
                        latitude: store.node.data.store_coordinates.latitude,
                        longitude: store.node.data.store_coordinates.longitude,
                        zoom: viewport.zoom,
                      })
                    }}
                  >
                    <img className={activestore === index ? "image-active" : "image"} src={Markersvg}></img>
                  </button>
                </Marker>
              )
            })}
          </div>
        )}
      </ReactMapGL>
    </div>
  )
}
export default Map
