import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Map from "../components/map"

import { graphql } from "gatsby"

const MapPage = props => {
  return (
    <Layout>
      <SEO
        title="Karte | helpyourlocalstore"
        description="„help your local store“ soll auf Liefer- und Abholangebote in Sindelfingen und Böblingen während der Corona-Zeit aufmerksam machen und ist für die Restaurants sowie für alle Nutzer komplett kostenfrei."
      />
      <Map mapdata={props.data.allPrismicStore} />
    </Layout>
  )
}

export const query = graphql`
  query MapQuery {
    allPrismicStore {
      edges {
        node {
          uid
          data {
            store_categorie
            store_coordinates {
              latitude
              longitude
            }
            store_title {
              text
            }
            store_desicription {
              text
            }
            store_logo {
              url
            }
            store_phonenumber
            store_openingtime {
              store_times {
                raw {
                  text
                }
              }
            }
            store_service
          }
        }
      }
    }
  }
`

export default MapPage
